import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Select,
    Textarea,
    VStack,
    Heading,
    useToast,
    Container,
    Text,
    Card,
    CardBody,
    Stack,
    StackDivider,
    Input,
} from "@chakra-ui/react";

const API_BASE_URL = "https://qaapirgeneng.digitalfields.co.za/ws1.cfc";

const Performance100 = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useToast();
    const { candidateId, candidateDets } = location.state || {};

    const HuserID = localStorage.getItem("HuserID");
    const UUID = localStorage.getItem("UUID");

    const [formData, setFormData] = useState({
        assessmentDate: "",
        potentialScore: "",
        performanceScore: "",
        overallScore: "",
        comments: "",
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateForm = () => {
        const newErrors = {};

        if (!formData.assessmentDate) {
            newErrors.assessmentDate = "An assessment date is required";
        }

        if (!formData.potentialScore) {
            newErrors.potentialScore = "Potential score is required";
        }

        if (!formData.potentialScore) {
            newErrors.potentialScore = "Potential score is required";
        }

        if (!formData.performanceScore) {
            newErrors.performanceScore = "Performance score is required";
        }

        if (!formData.overallScore) {
            newErrors.overallScore = "Overall score is required";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleDateChange = (e) => {
        const { name, value } = e.target;

        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));

        if (errors[name]) {
            setErrors((prev) => ({
                ...prev,
                [name]: "",
            }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));

        if (errors[name]) {
            setErrors((prev) => ({
                ...prev,
                [name]: "",
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setIsSubmitting(true);

        try {
            const scoresData = {
                assessmentDate: parseInt(formData.assessmentDate, 10),
                potentialScore: parseInt(formData.potentialScore, 10),
                performanceScore: parseInt(formData.performanceScore, 10),
                overallScore: parseInt(formData.overallScore, 10),
                comments: formData.comments,
            };

            const url = new URL(`${API_BASE_URL}`);
            url.searchParams.append("method", "addperf100");
            url.searchParams.append("huserID", HuserID);
            url.searchParams.append("UUID", UUID);
            url.searchParams.append("candidateID", candidateId);
            url.searchParams.append("scores", JSON.stringify(scoresData));

            const response = await fetch(url);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(
                    `HTTP error ${response.status}: ${errorData.message}`
                );
            }

            const data = await response.json();
            console.log("Server response:", data);

            toast({
                title: "Success",
                description: "Performance scores have been saved successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

            navigate(`/candidate/${candidateId}`);
        } catch (error) {
            console.error("Submission error:", error);
            toast({
                title: "Error",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCancel = () => {
        navigate(`/candidate/${candidateId}`);
    };

    if (!candidateId || !candidateDets) {
        return (
            <Container maxW="container.md" py={8}>
                <Heading size="md" mb={4}>
                    Error: No candidate selected
                </Heading>
                <Button onClick={() => navigate("/candidates")}>
                    Return to Candidates
                </Button>
            </Container>
        );
    }

    return (
        <Container maxW="container.md" py={8}>
            <Card mb={6}>
                <CardBody>
                    <Stack divider={<StackDivider />} spacing={4}>
                        <Box>
                            <Heading size="md" mb={2}>
                                Candidate Details
                            </Heading>
                            <Text>
                                <strong>Name:</strong>{" "}
                                {`${candidateDets.fname} ${candidateDets.sname}`}
                            </Text>
                            <Text>
                                <strong>Employee Number:</strong>{" "}
                                {candidateDets.employeeNo || "N/A"}
                            </Text>
                            <Text>
                                <strong>Email:</strong> {candidateDets.email}
                            </Text>
                        </Box>
                    </Stack>
                </CardBody>
            </Card>

            <Box as="form" onSubmit={handleSubmit}>
                <Heading size="lg" mb={6}>
                    9 Box Grid Performance Capture
                </Heading>

                <VStack spacing={6} align="stretch">
                    <FormControl isRequired isInvalid={errors.assessmentDate}>
                        <Input
                            name="assessmentDate"
                            value={formData.assessmentDate}
                            placeholder="Date Of Assessment"
                            size="md"
                            type="date"
                            onChange={handleDateChange}
                        />
                        <FormErrorMessage>
                            {errors.assessmentDate}
                        </FormErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={errors.potentialScore}>
                        <FormLabel>Potential Score</FormLabel>
                        <Select
                            name="potentialScore"
                            value={formData.potentialScore}
                            onChange={handleChange}
                            placeholder="Select potential score"
                        >
                            {[1, 2, 3].map((score) => (
                                <option key={score} value={score}>
                                    {score}
                                </option>
                            ))}
                        </Select>
                        <FormErrorMessage>
                            {errors.potentialScore}
                        </FormErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={errors.performanceScore}>
                        <FormLabel>Performance Score</FormLabel>
                        <Select
                            name="performanceScore"
                            value={formData.performanceScore}
                            onChange={handleChange}
                            placeholder="Select performance score"
                        >
                            {[1, 2, 3].map((score) => (
                                <option key={score} value={score}>
                                    {score}
                                </option>
                            ))}
                        </Select>
                        <FormErrorMessage>
                            {errors.performanceScore}
                        </FormErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={errors.overallScore}>
                        <FormLabel>Overall Score</FormLabel>
                        <Select
                            name="overallScore"
                            value={formData.overallScore}
                            onChange={handleChange}
                            placeholder="Select overall score"
                        >
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((score) => (
                                <option key={score} value={score}>
                                    {score}
                                </option>
                            ))}
                        </Select>
                        <FormErrorMessage>
                            {errors.overallScore}
                        </FormErrorMessage>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Comments</FormLabel>
                        <Textarea
                            name="comments"
                            value={formData.comments}
                            onChange={handleChange}
                            placeholder="Enter any comments here (optional)"
                            rows={4}
                        />
                    </FormControl>

                    <Box display="flex" gap={4} justifyContent="flex-end">
                        <Button onClick={handleCancel} variant="outline">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            colorScheme="blue"
                            isLoading={isSubmitting}
                        >
                            Submit
                        </Button>
                    </Box>
                </VStack>
            </Box>
        </Container>
    );
};

export default Performance100;
